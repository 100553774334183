import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/EcoClub/evday/1.jpg';
import p2 from 'assests/Photos/Clubs/EcoClub/evday/2.jpg';
import p3 from 'assests/Photos/Clubs/EcoClub/evday/3.jpg';
import p4 from 'assests/Photos/Clubs/EcoClub/evday/5.jpg';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import Ecoclub2023 from './Ecoclub2023';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar from 'views/EcoClub/Sidebar';


const EnvironmentDay2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },

    // {
    //     src: p4,
    //     source: p4,
    //     rows: 1,
    //     cols: 1,
    //   },
    // // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
   <Main>
        <Container>
    <Grid container spacing={4}>
    <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      World Environment Day-2023
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
        Class: 6 to 12 & Date: 05 June 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of classes 9 to 12 presented the assembly on Monday, 05 June, 2023 on World
Environment Day.
The programme began with a thought provoking talk, followed by a brief introduction to the theme of
World Environment Day-2023. Students presented a melodious rendition on importance of water –
Swachata aur Pani. A short mime presented by the students depicted some simple steps that an
individual can follow to protect our Earth. An interactive quiz by students added to the cornucopia of
events. Principal Ms Sheeja Manoj, highlighted the importance of 4R’s, especially REFUTE to counter the
plastic menace.
The programme concluded by students taking a pledge to be responsible towards the environment.
        </Typography>
      </Box>


      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Ecoclub2023 />
             </Box>

             <Box marginBottom={4}>
               <Sidebar/>
             </Box>

    </Grid>

    </Grid> 
    </Container>
    </Main>
   
  );
};

export default EnvironmentDay2023;